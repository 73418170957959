(function(i, p, w, d, b, t) {

    var video_ad = {

        adSize: function() {
            var obj = {
                adHeight: 250,
                adWidth: 300,
                isInset: (this.settings.ad_size.indexOf('Inset') > -1) ? true : false
            };
            obj.videoHeight = (obj.isInset) ? 157 : 168;
            obj.videoWidth = (obj.isInset) ? 280 : 300;

            if (this.settings.ad_size.indexOf('300x250') > -1) {
                // continue - use default settings
            } else if (this.settings.ad_size.indexOf('300x600') > -1) {
                obj.adHeight = 600;
                obj.adWidth = 300;
            } else if (this.settings.ad_size.indexOf('970x250') > -1) {
                obj.adHeight = 250;
                obj.adWidth = 970;
                obj.videoHeight = 230;
                obj.videoWidth = 410;
            } else {
                throw 'Unrecognized ad size.';
            }
            return obj;
        },

        css: function(adSize) {
            var css = d.createElement('style'),
                styles = '';

            styles = '#'+this.id + ' { height: ' + adSize.adHeight + 'px; width: ' + adSize.adWidth + 'px; margin: auto; }';
            if (this.settings.background_image) styles += '#'+this.id + ' .x-video-ad { width: 100%; height: 100%; background: url("' + this.settings.background_image + '") no-repeat; }';
            if (adSize.isInset) styles += '#'+this.id + ' .x-video-player { padding: 10px; }';
            if (adSize.adWidth === 970) styles += '#'+this.id + ' .x-video-player { float: right; }';

            css.type = 'text/css';
            css.appendChild(d.createTextNode(styles));
            return css;
        },

        html: function(adSize, isYouTube) {
            var videoContainer = d.createElement('div'),
                videoLink = d.createElement('a'),
                videoAd = d.createElement('div'),
                videoPlayer = d.createElement('div'),
                videoTag = (isYouTube) ? this.youTubePlayer(adSize, this.settings) : this.videoPlayer(adSize, this.settings);

            videoContainer.id = this.id;
            videoLink.href = this.settings.click_tag;
            videoLink.target = '_blank';
            videoLink.rel = 'noopener';
            videoAd.className = 'x-video-ad';
            videoPlayer.className = 'x-video-player';

            videoPlayer.appendChild(videoTag);
            videoAd.appendChild(videoPlayer);
            videoLink.appendChild(videoAd);
            videoContainer.appendChild(videoLink);

            if (this.settings.tracking_pixel) {
                var pixelContainer = d.createElement('div'),
                    pixelImg = d.createElement('img');

                pixelContainer.style.display = 'none';
                pixelImg.src = this.settings.trackingPixel;
                pixelImg.height = '1';
                pixelImg.width = '1';

                pixelContainer.appendChild(pixelImg);
                videoContainer.appendChild(pixelContainer);
            }

            return videoContainer;
        },

        videoEvents: function() {
            var videoPlayer = d.querySelector('#'+this.id + ' .x-video-player video');

            videoPlayer.addEventListener('click', function(e) {
                e.preventDefault();
                (videoPlayer.paused) ? videoPlayer.play() : videoPlayer.pause();
            });
        },

        youTubeAutoplay: function() {
            var that = this;
            w.onPlayerReady = function(e) {
                w.ytPlayer.playVideo();
                e.target.mute();
            };
            w.onYouTubeIframeAPIReady = function() {
                w.ytPlayer = new w.YT.Player(that.ytId, {
                    events: {
                        'onReady': w.onPlayerReady,
                    }
                });
            };

            var tag = d.createElement('script');
                tag.src = '//www.youtube.com/iframe_api';
                tag.async = true;
            var firstScriptTag = d.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },

        youTubePlayer: function(adSize) {
            var iframe = d.createElement('iframe'),
                videoUrl = this.settings.video_url;

            if (this.settings.play_options.indexOf('Auto-play') > -1) {
                this.youTubeAutoplay();
                videoUrl = videoUrl + ((videoUrl.indexOf('?') > -1) ? '&' : '?') + 'enablejsapi=1';
            }
            if (this.settings.loop === 'True') videoUrl = videoUrl + ((videoUrl.indexOf('?') > -1) ? '&' : '?') + 'loop=1';

            iframe.id = this.ytId;
            iframe.width = adSize.videoWidth;
            iframe.height = adSize.videoHeight;
            iframe.src = videoUrl;
            iframe.frameborder = '0';
            iframe.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
            iframe.setAttribute('allowfullscreen', '');
            return iframe;
        },

        videoPlayer: function(adSize) {
            var videoTag = d.createElement('video'),
                videoSrc = d.createElement('source'),
                videoFormat;

            if (this.settings.video_url.indexOf('.mp4') > -1) {
                videoFormat = 'video/mp4';
            } else if (this.settings.video_url.indexOf('.webm') > -1) {
                videoFormat = 'video/webm';
            } else if (this.settings.video_url.indexOf('.ogg') > -1) {
                videoFormat = 'video/ogg';
            } else {
                throw 'Unrecognized video media type.';
            }

            videoTag.width = adSize.videoWidth;
            videoTag.height = adSize.videoHeight;
            videoTag.setAttribute('controls', '');

            if (this.settings.loop === 'True') videoTag.setAttribute('loop', '');

            // This hack implementation of auto-play is required because the traditional combination of autoplay and muted attributes no longer functions.
            if (this.settings.play_options.indexOf('Auto-play') > -1) videoTag.setAttribute('oncanplay', 'this.muted=true;this.play();');

            videoSrc.src = this.settings.video_url;
            videoSrc.type = videoFormat;

            videoTag.appendChild(videoSrc);
            return videoTag;
        },

        load: function(settings) {
            this.settings = settings;
            this.id = 'x-video-container-'+ this.settings.line_item_id +'-'+ this.settings.creative_id;
            this.ytId = 'x-video-yt-frame-'+ this.settings.line_item_id +'-'+ this.settings.creative_id;

            var adSize = this.adSize(this.settings),
                isYouTube = (this.settings.video_url.indexOf('youtube') > -1) ? true : false;

            p.style.display = 'none';
            p.parentNode.appendChild(this.css(adSize));
            p.parentNode.appendChild(this.html(adSize, isYouTube));
            if (!isYouTube) this.videoEvents();
        }

    };

    try {
        if (!i) throw 'Friendly iframe required.';

        t.cmds.forEach(function(item) {
            video_ad.load(item.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);